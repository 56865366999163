
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    // VueTelInput,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      name: "",
      email: "",
      number: "",
      number_price: "",
      city: "",
      state: "",
      zip_code: "",
      address: "",
      timezone: "",
    });
    const numberList = ref([]);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const phone_number = ref<string>("");
    const page = ref<string>("");
    const inputOptions = ref<any>({
      styleClasses: "form-control form-control-lg form-control-solid",
    });
    // const value = ref('');
    const registration = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      number: Yup.number()
        .typeError("Please Speacify a number")
        .required()
        .label("Number"),
      // number_price: Yup.number()
      //   .typeError("please specify number")
      //   .required()
      //   .label("Number Price"),
      city: Yup.string().required().label("City"),
      state: Yup.string().required().label("State"),
      zip_code: Yup.number()
        .required()
        .typeError("Please specify the number")
        .label("Zip Code"),
      address: Yup.mixed().required().label("Property Address"),
      timezone: Yup.string().required().label("TimeZone"),
    });

    onMounted(() => {
      getTimezone();
      const userData = localStorage.getItem("userdata");
      if (userData) {
        var allData = JSON.parse("" + userData + "");
        if (allData.type == "admin") {
          const data = route.params.id;
          if (data) {
            getUser(data);
            // page.value = "Edit";
            setCurrentPageBreadcrumbs("Edit User", ["User"]);
          } else {
            // page.value = "Add";
            setCurrentPageBreadcrumbs("Add User", ["User"]);
          }
          // setCurrentPageBreadcrumbs("Add User", ["User"]);
          store.dispatch(Actions.GET_AUTH_USER).then((data) => {
            //formData.value = data
            profile.value = data;
          });
          nextTick(() => {
            PasswordMeterComponent.bootstrap();
          });
          const id = route.params.id;
          if (id) {
            // getUser(id);

            page.value = "Edit";
          } else {
            page.value = "Add";
          }
        } else {
          router.push({ name: "dashbord" });
        }
      }
    });

    const getTimezone = () => {
      var request = {
        url: `timezone/get`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          numberList.value = data.data;
        }
      });
    };
    const getUser = (id) => {
      var request = {
        url: `oneuser/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          formData.value = {
            name: data.data.name,
            email: data.data.email,
            number: data.data.number,
            number_price: data.data.number_price,
            city: data.data.city,
            state: data.data.state,
            zip_code: data.data.zip_code,
            address: data.data.address,
            timezone: data.data.timezone,
          };
          var test = data.data.number;
          phone_number.value = test;
        }
      });
    };

    const testFunction = (event, phoneObject) => {
      // console.log(JSON.stringify(phoneObject));
      if (phoneObject !== undefined) {
        // console.log(event);
        // console.log(phoneObject);

        validNumber.value = phoneObject;
        if (phoneObject.valid) {
          formData.value.number = phoneObject.number;
        } else {
          formData.value.number = "";
        }
      }
    };
    const onSubmitRegister = (values, { resetForm }) => {
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // console.log('values',values);
      if (values.name.trim() == "") {
        Swal.fire({
          text: "Please Enter the name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.number.length != 10) {
        Swal.fire({
          text: "Please enter 10 digit number!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.zip_code.length > 6) {
        Swal.fire({
          text: "Please enter 6 digit Zip code!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var id = route.params.id;
        var request = {
          url: `user/${id}`,
          data: {
            name: values.name,
            email: values.email,
            number: values.number,
            city: values.city,
            state: values.state,
            zip_code: values.zip_code,
            address: values.address,
            number_price: values.number_price,
            timezone: values.timezone,
          },
        };
        store
          .dispatch(Actions.PUT, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "User Updated successfully!", "success");
              router.push({ name: "admin-user" });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
      //   Swal.fire({
      //     text: "User added successfully!",
      //     icon: "success",
      //     buttonsStyling: false,
      //     confirmButtonText: "Ok, got it!",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-primary",
      //     },
      //   });
      //   .then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      testFunction,
      validNumber,
      phone_number,
      formData,
      inputOptions,
      page,
      getUser,
      numberList,
    };
  },
});
